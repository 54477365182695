<template>
  <b-card header="Edit User Permissions">
    <b-tabs>
      <b-tab
        title="Admin Permissions"
        lazy
      >
        <permissions-table
          :selected-permissions.sync="admin.selectedPermissions"
          :selected-groups.sync="admin.selectedGroups"
          :available-permissions-grouped="admin.availablePermissionsGrouped"
          @assignPermissions="assignUserPermissions"
          @revokePermissions="revokeUserPermissions"
        />
      </b-tab>

      <b-tab
        title="Entity Permissions"
        lazy
      >
        <permissions-table
          :selected-permissions.sync="entity.selectedPermissions"
          :selected-groups.sync="entity.selectedGroups"
          :available-permissions-grouped="entity.availablePermissionsGrouped"
          @assignPermissions="assignUserPermissions"
          @revokePermissions="revokeUserPermissions"
        />
      </b-tab>
    </b-tabs>
    <back />
  </b-card>
</template>
<script>
import Back from '@/common/components/common/FormInputs/Back.vue'
import PermissionsTable from '@/common/components/Permissions/PermissionsTable.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import UserPermissions from '@/common/compositions/permissions/userPermissions'

export default {
  name: 'EditUserPermissions',
  components: {
    Back,
    PermissionsTable,
  },
  data() {
    return {
      entity: {
        selectedPermissions: [],
        selectedGroups: [],
        availablePermissionsGrouped: [],
      },
      admin: {
        selectedPermissions: [],
        selectedGroups: [],
        availablePermissionsGrouped: [],
      },
    }
  },
  computed: {
    types() {
      return {
        admin: 'both,system-admin',
        entity: 'both,entity',
      }
    },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const { getUserPermissionsInEntity, assignUserPermissions, revokeUserPermissions } = UserPermissions()
    return {
      assignUserPermissions, revokeUserPermissions, successfulOperationAlert, getUserPermissionsInEntity,
    }
  },
  created() {
    Object.entries(this.types).forEach(([type, scopeType]) => {
      this.getAvailablePermissionsGrouped(type, scopeType)
      this.getSelectedUserPermissions(type, scopeType)
    })
  },
  methods: {
    getAvailablePermissionsGrouped(type, scopeType) {
      return this.getUserPermissionsInEntity(
        this.$store.getters['auth/getUserData'].username,
        '',
        { scopeType },
      ).then(permissionsGrouped => {
        this.$set(this[type], 'availablePermissionsGrouped', permissionsGrouped)
      })
    },
    getSelectedUserPermissions(type, scopeType) {
      this.getUserPermissionsInEntity(
        this.$route.params.username,
        '',
        { scopeType },
      ).then(permissionsGroup => {
        const permissionsList = permissionsGroup.map(group => group.permissions).flat()
        this.$set(this[type], 'selectedPermissions', permissionsList.map(permission => permission.name))

        this.$set(this[type], 'selectedGroups', permissionsGroup.map(group => group.groupName))
      })
    },
  },
}
</script>
<style lang="scss">

</style>
